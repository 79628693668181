<template>
  <div class="detail-box">
    <el-row class="title-box">
      <el-col class="title">
        <span>{{ title }}</span>
      </el-col>
      <el-col :span="18" class="time-col">
        <span class="publish">{{ time }}</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    <!--发布时间：-->
        <span class="publish">来源：{{ xxly }}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span class="publish" v-show="zz">作者：{{ zz }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span>浏览次数：{{ ydl }}</span>
      </el-col>
      <el-col :span="6" class="font-col">
        <span class="font">
          字体：【
          <a @click="big()" :class="{active:textSize === 'big'}">大</a>
          <a @click="medium()" :class="{active:textSize === 'medium'}">中</a>
          <a @click="small()" :class="{active:textSize === 'small'}">小</a>】
        </span>
      </el-col>
    </el-row>

    <el-row :class="textSize" class="text-box">
      <div class="con-box" v-html="contentText">
      </div>
<!--      <el-col class="author-col">-->
<!--        <span class="from">发布单位：{{ dw }}</span>-->
<!--        <span class="author">发布人：{{ author }}</span>-->
<!--      </el-col>-->
<!--      <el-col class="QR-col">-->
<!--        <p><span class="tips">扫描二维码从微信进入</span></p>-->
<!--        <div ref="qr" style="margin-left: auto; margin-right: auto; width: 140px"></div>-->
<!--      </el-col>-->
      <ul class="operate">
        <li>
          <el-popover
              :content="collectText"
              placement="left"
              trigger="hover">
            <span slot="reference" :class="{'collect': isCollect}" class="star" @click="handleCollect"></span>
          </el-popover>
        </li>
        <li>
          <el-popover
              placement="left-start"
              trigger="hover"
              width="240">
            <div class="share-cont">
              <p class="title"><span>分享至</span></p>
              <ul class="im">
                <share :config="share_config"></share>
              </ul>
            </div>
            <span slot="reference" class="share"></span>
          </el-popover>
        </li>
      </ul>
    </el-row>
  </div>
</template>

<script>
import LoginCard from "@/views/login/loginCard";
import breadCrumb from "@/components/common/breadCrumb";
import QRCode from "qrcodejs2";
import {checkCollection, getCollectionState} from "@/api/personService";
import {getGraphInfoById} from "@/api/homeService";
import storage from "@/utils/storage";
import {checkToken} from "@/api/systemService";
import "@/assets/css/detail.scss"
import {isNull} from "../utils/utils-function";

export default {
  name: "articleShow",
  components: {
    LoginCard,
    breadCrumb,
    QRCode
  },
  data() {
    return {
      id: '',
      textSize: 'medium',
      isActive: true,
      isCollect: false,
      collectText: '收藏',
      title: '',
      contentText: '',
      time: '',
      xxly: '',
      author: '',
      dw: '',
      ydl: 0,
      zz:'',
      share_config: {
        url: window.location.href,
        sites: ['weibo', 'qq', 'wechat', 'qzone']
      },
    }
  },
  created() {
    this.getCollection();
    this.getContent();
    // this.createQRCOde();
  },
  mounted() {

  },
  methods: {
    /**
     * 收藏操作
     */
    handleCollect() {
      const param = {graphId: this.id, type: 'collection'};
      checkCollection(param).then(res => {
        if (res.data !== null && res.data === '1') {
          this.isCollect = true;
          this.collectText = '已收藏';
        } else if (res.data !== null && res.data === '0') {
          this.isCollect = false;
          this.collectText = '收藏';
        } else {
          this.$message({
            message: res.data,
            type: 'warning'
          })
        }
      })
    },

    /**
     * 获取图文详情
     */
    getContent() {
      const id = this.$route.query.id;
      this.id = id;
      getGraphInfoById(id).then(res => {
        const data = res.data;
        this.title = data.bt;
        this.contentText = data.nr;
        this.time = data.fbsj;
        this.author = data.fbr;
        this.ydl = data.ydl;
        this.xxly = data.xxly
        this.dw = data.dw
        this.zz = data.zz
        document.title = isNull(this.title) ? '详情' : this.title;
      })
    },

    /**
     * 生成二维码
     */
    createQRCOde() {
      const url = window.location.href;
      const qrcode = new QRCode(this.$refs.qr, {
        text: url,
        width: 140,
        height: 140,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      });
    },
    //变大
    big() {
      this.textSize = 'big'
    },
    medium() {
      this.textSize = 'medium'
    },
    small() {
      this.textSize = 'small'
    },

    /**
     * 获取收藏状态
     */
    getCollection() {
      const user = storage.get('user');
      if (user !== undefined && user !== null) {
        checkToken({"token": user.access_token}).then(res => {
          if (res.status == 200) {
            const id = this.$route.query.id;
            const param = {graphId: id, type: 'collection'};
            getCollectionState(param).then(res => {
              if (res.data !== null) {
                this.isCollect = true;
                this.collectText = "已收藏";
              } else {
                this.isCollect = false;
                this.collectText = "收藏";
              }
            })
          } else {
            storage.remove("user");
          }
        }).catch(res => {
          console.log(res)
        })
      }
    },
  }
}
</script>

<style scoped lang="scss">
  .detail-box{
    ::v-deep .con-box span {
      font-size: inherit !important;
    }
    ::v-deep .con-box img {
      max-width: 1200px;
    }
  }

</style>
